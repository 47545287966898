import Firebase from 'firebase'

const config= {
  apiKey: "AIzaSyC2TizoJSheddmV6agssbg4pvuW9aXlIyo",
  authDomain: "sanar-231614.firebaseapp.com",
  databaseURL: "https://sanar-231614.firebaseio.com",
  projectId: "sanar-231614",
  storageBucket: "sanar-231614.appspot.com",
  messagingSenderId: "466428639482",
  appId: "1:466428639482:web:2745345887a1a6531c7143",
  measurementId: "G-D4FC8ZXMS6"
}
const firebaseApp = !Firebase.apps.length ? Firebase.initializeApp(config) : Firebase.app();

// Export the database for components to use.
// If you want to get fancy, use mixins or provide / inject to avoid redundant imports.
export const db = firebaseApp.database();